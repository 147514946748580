"use client";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";

import { Button } from "@/components/ui/inputs/button/button";
import { Stack } from "@/components/ui/layout/stack";

export function HeaderAnonymous() {
  const { status } = useSession();
  const isLoading = status === "loading";

  return (
    <Stack direction="row" spacing="xs">
      {status === "authenticated" ? (
        <>
          <Button
            variant="link"
            onClick={() =>
              signOut({
                callbackUrl: "/",
              })
            }
            size="sm"
            disabled={isLoading}
          >
            Cerrar sesión
          </Button>
          <Button variant="outline" asChild size="sm" disabled={isLoading}>
            <Link href="/dashboard">Dashboard</Link>
          </Button>
        </>
      ) : (
        <>
          <Button variant="outline" asChild size="sm">
            <Link href="/auth/login">Ingresa</Link>
          </Button>
          <Button asChild size="sm">
            <Link href="/auth/register">Únete a Dapper</Link>
          </Button>
        </>
      )}
    </Stack>
  );
}

HeaderAnonymous.displayName = "HeaderAnonymous";
