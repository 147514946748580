"use client";

import { useGetHome } from "./_api";
import {
  Banner,
  Clients,
  Hero,
  KeyWords,
  PublicListening,
  ScrollyTelling,
  Stats,
  Testimonial,
  VideoJumbo,
} from "./_components/sections";

export default function Page() {
  const { data } = useGetHome();

  const { keywords } = data;

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="relative z-10">
          <Hero />
        </div>

        <VideoJumbo />
      </div>

      <ScrollyTelling />

      <Stats />

      <Clients />

      <Testimonial />

      <KeyWords {...keywords} />

      <PublicListening />

      <Banner />
    </>
  );
}
