"use client";

import { motion as m } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useLayoutEffect, useRef } from "react";

import { useLandingContentsGetSuspense as useLandingContentsGet } from "architecture/src/shared/api/endpoints/landing/landing";

import DapperIsoLogo from "public/static/images/home/dapper-iso-logo-dark.svg";

import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button/button";
import { Stack } from "@/components/ui/layout/stack";
import { Card } from "@/components/ui/surfaces/card";
import { StatsData } from "./components";
import { useStatsStore } from "./store";

const DapperImage = () => (
  <Image
    src={DapperIsoLogo}
    alt="Dapper"
    className="w-64 md:w-[30rem]"
    sizes={"(min-width: 768px) 50vw, 632px"}
  />
);

const Dot = () => (
  <div className="relative w-12 h-12">
    {/* Mask */}
    <div className="absolute inset-x-0 h-3 -mx-4 bottom-3 bg-primary-foreground" />
    {/* Background */}
    <div className="w-full h-full rounded-full bg-primary-foreground" />
    {/* Dot */}
    <div className="absolute w-3 h-3 -translate-x-1/2 -translate-y-1/2 rounded-full rounded-tr-none bg-primary top-1/2 left-1/2" />
    {/* Left border radius */}
    <div className="absolute w-12 h-12 rounded-full -bottom-6 bg-primary -left-[2.6rem]" />
    {/* Right border radius */}
    <div className="absolute w-12 h-12 rounded-full -bottom-6 bg-primary -right-[2.6rem]" />
  </div>
);

const useStats = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { setRefSection } = useStatsStore();

  useLayoutEffect(() => {
    setRefSection(ref);
  }, [ref, setRefSection]);

  return {
    ref,
  };
};

export const Stats: React.FC<{}> = () => {
  const { ref } = useStats();
  const { data: content } = useLandingContentsGet("1");

  if (!content) return null;

  return (
    <m.section ref={ref} className="my-10">
      <div className="container mx-auto">
        {/* Card */}
        <Card rounded="max" variant="primary">
          <Stack spacing="md" className="relative px-4 py-20 text-center">
            {/* Dots */}
            <Stack
              className="absolute inset-x-0 px-8 overflow-hidden -top-6"
              direction="row"
              justify="spaceAround"
            >
              <Dot />
              <Dot />
              <Dot />
            </Stack>

            <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none">
              {/* Left */}
              <div className="absolute -left-[18%] -top-[36%] md:-left-[6rem] md:-top-[18rem]">
                <DapperImage />
              </div>

              {/* Right */}
              <div className="absolute -right-[20%] -bottom-[19%] md:-right-[7rem] md:-bottom-[10rem]">
                <DapperImage />
              </div>
            </div>

            <Typography variant="h3">{content.title}</Typography>

            {/* List */}
            <div className="overflow-hidden">
              <StatsData {...content} />
            </div>

            <Typography variant="h4">{content.summary}</Typography>

            {/* CTA */}
            {content.textButton ? (
              <div className="dark">
                <Button asChild>
                  <Link href={content.urlButton ?? ""}>
                    {content.textButton}
                  </Link>
                </Button>
              </div>
            ) : null}
          </Stack>
        </Card>
      </div>
    </m.section>
  );
};
